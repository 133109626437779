// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        // stylelint-disable-next-line scss/selector-no-union-class-name
        &#{$infix} {
            @include media-breakpoint-down($next) {
                .offcanvas-header {
                    border-bottom: 1px solid var(--#{$prefix}gray-300);
                }
                > .container {
                    position: relative;
                }
                .navbar-nav {
                    > .nav-item {
                        border-bottom: 1px solid var(--#{$prefix}gray-300);
                        > .dropdown-toggle {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            &:after {
                                content: "\f282";
                                display: inline-block;
                                font-family: bootstrap-icons !important;
                                font-style: normal;
                                font-weight: normal !important;
                                font-variant: normal;
                                text-transform: none;
                                line-height: 1;
                                vertical-align: -.125em;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                color: currentColor;
                                font-size: 75%;
                                margin-left: 7px;
                                border: none;
                            }
                        }
                    }
                }
                .dropdown-menu {
                    border: none;
                    padding: 10px 0;
                    background: var(--#{$prefix}gray-200);
                    margin: 0 0 10px;
                    font-size: 0.90rem;
                }
            }

            @include media-breakpoint-up($next) {
                --bs-navbar-padding-y: 0;
                >.container {
                    position: relative;
                }

                .px-dropdown-toggle {
                    display: none;
                }            

                .navbar-nav {

                    >.nav-item {
                        &.dropdown {
                            >.nav-link {
                                &:after {
                                    content: "\f282";
                                    display: inline-block;
                                    font-family: bootstrap-icons !important;
                                    font-style: normal;
                                    font-weight: normal !important;
                                    font-variant: normal;
                                    text-transform: none;
                                    line-height: 1;
                                    vertical-align: -.125em;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    color: currentColor;
                                    font-size: 75%;
                                    margin-left: 7px;
                                    border: none;
                                }
                            }
                            // dropdown full width
                            &.dropdown-full {
                                position: static;
                            }
                        }

                        &:hover {
                            >.dropdown-menu {
                                opacity: 1;
                                pointer-events: auto;
                            }
                        }
                    }

                    .px-mega-dropdown {
                        position: static;
                    }
                    .dropdown-mega-menu {
                        width: 100%;
                    }

                    // Dropdown Menu
                    .dropdown-menu {
                        display: block;
                        opacity: 0;
                        pointer-events: none;
                        transition: ease all 0.35s;
                        margin: 0;
                        left: 50%;
                        transform: translate(-50%, 0);
                        box-shadow: $box-shadow-lg;
                        border-top: 2px solid $primary;

                        &.start {
                            left: 0;
                            transform: translate(0, 0);
                        }

                        &.end {
                            left: auto;
                            right: 0;
                            transform: translate(0, 0);
                        }

                        // Sub Menu
                        .dropdown {
                            >.dropdown-item {
                                position: relative;
                                padding-right: 18px;

                                &:after {
                                    content: "\f285";
                                    display: inline-block;
                                    font-family: bootstrap-icons !important;
                                    font-style: normal;
                                    font-weight: normal !important;
                                    font-variant: normal;
                                    text-transform: none;
                                    line-height: 1;
                                    vertical-align: -.125em;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    color: currentColor;
                                    font-size: 85%;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    right: 10px;
                                    height: 10px;
                                }
                            }

                            &:hover {
                                .dropdown-menu-sub {
                                    opacity: 1;
                                    pointer-events: auto;
                                }
                            }
                        }

                        .dropdown-menu-sub {
                            position: absolute;
                            top: 0;
                            left: 100%;
                            opacity: 0;
                            pointer-events: none;
                            transform: translate(0, 0);

                            &.end {
                                left: auto;
                                right: 100%;
                            }
                        }

                        // Menu size
                        &.dropdown-menu-md {
                            min-width: $px-dropdown-menu-md;
                        }

                        &.dropdown-menu-lg {
                            min-width: $px-dropdown-menu-lg;
                        }

                        &.dropdown-menu-xl {
                            min-width: $px-dropdown-menu-xl;
                        }

                        &.px-mega-menu {
                            width: 100%;
                        }

                        // Dropdown Item
                        .dropdown-item {
                            padding: 0.4rem 1rem;
                        }
                    }
                }
            }
        }
    }
}




// Navbar transparent
.header-transparent {
    .header-navbar-brand {
        .logo-dark {
            display: block;
        }

        .logo-light {
            display: none;
        }
    }
    &.header-transparent-light {
        .navbar-toggler {
            .toggler-icon {
                color: $white;
            }
        }
        
        .header-navbar-brand {
            .logo-dark {
                display: none;
            }

            .logo-light {
                display: block;
            }
        }
        .navbar-toggler {
            border-color: $navbar-dark-toggler-border-color;
            .navbar-toggler-icon {
                background-image: $navbar-dark-toggler-icon-bg;
            }
        }
    }
}

.header-transparent.headroom--not-top {
    &.header-transparent-light {
        .navbar-toggler {
            .toggler-icon {
                color: $dark;
            }
        }
        .header-navbar-brand {
            .logo-dark {
                display: block;
            }

            .logo-light {
                display: none;
            }
        }
    }
}
.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        // stylelint-disable-next-line scss/selector-no-union-class-name
        &#{$infix} {
            @include media-breakpoint-up($next) {
                .header-transparent.header-transparent-light:not(.headroom--not-top) & {
                    .navbar-nav {
                        .nav-link {
                            color: rgba(var(--bs-white-rgb), 0.85);
                            
                            &:hover {
                                color: rgba(var(--bs-white-rgb), 1);
                            }
                        }
                    }
                }
            }
        }
    }
}

// Header Fixed Effect
.main-header {
    &.headroom--not-top {
        box-shadow: $box-shadow;
    }
    &.navbar-light.headroom--not-top {
        background-color: var(--#{$prefix}white);
    }
}


.navbar-toggler {
    padding: 0;
    width: 35px;
    height: 35px;
    position: relative;
    border: none;
    color: rgba(var(--#{$prefix}dark-rgb), 1);
    margin-left: 10px;
    .toggler-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 17px;
        box-shadow: 0 0 0 1px currentColor, 0px -7px 0 1px currentColor, 0 7px 0 1px currentColor;
        height: 0;
    }
}
